import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { TUser } from "./UserModel";
import { TRoute, useRouteStates } from "./RouteModel";
import { TCardMaster, TDisplayMaster, TTotalMaster } from "./CardMasterModel";
import { useSearchValuesState2 } from "globalStates/cardSearchValuesState";

export const cardOptionalSettings = { hide_code: "CODE非表示", default_right: "右端初期表示" } as const;
export type TCard = {
  uuid: string;
  route_id: TRoute["id"];
  card_master_id: TCardMaster["id"];
  total_id?: TTotalMaster["id"];
  display_id?: TDisplayMaster["id"];
  vh_mode?: TDisplayMaster["vhMode"];
  title?: string;
  card_title?: string; // [ToDo]削除予定
  kamoku_list?: string[];
  kamoku_list_mode?: "show" | "hide";
  kamoku_sort?: string[];
  subfield_list?: string[];
  subfield_list_mode?: "show" | "hide";
  month?: {
    fromY?: number;
    fromM?: number;
    toY?: number;
    toM?: number;
    includeM?: number[];
  };
  chart?: any;
  is_tmp?: boolean;
  search_values?: any;
  rows_per_page?: any;
  price_scale?: 1 | 1000 | 1000000;
  optional_settings?: Array<keyof typeof cardOptionalSettings>;
};

const cardState = atomFamily<TCard, TCard["uuid"]>({
  key: "cardState",
  default: undefined,
});

export type TCardIds = string[];
const cardIdsState = atom<TCardIds>({
  key: "cardIdsState",
  default: [],
});

type TRouteCardIds = TCard["uuid"][];
type TRouteId = TRoute["id"];
const routeCardIdsState = atomFamily<TRouteCardIds, TRouteId>({
  key: "routeCardIdsState",
  default: [],
});

const allCardsSelector = selector<TCard[]>({
  key: "allCardsSelector",
  get: ({ get }) => {
    const ids = get(cardIdsState);
    return ids.map((cardId) => get(cardState(cardId)));
  },
});

export const useInitCards = () => {
  const { setSearchValues, setSearchValuesIds } = useSearchValuesState2();

  const initCards = useRecoilCallback(() => async (user: TUser, routes: TRoute[], cards2?: TCard[]) => {
    const cards = user?.cards as TCard[];

    routes && cards && initRouteCardIds({ routes, cards });
    cards && (await registCards(cards));
  });

  type TArgs = {
    routes: TRoute[];
    cards: TCard[];
  };
  const initRouteCardIds = useRecoilCallback(({ reset }) => ({ routes, cards }: TArgs) => {
    const cardRoutes = cards.map((card) => card.route_id);
    routes.filter((route) => cardRoutes.indexOf(route.id) < 0).forEach((route) => reset(routeCardIdsState(route.id)));
  });

  const registCards = useRecoilCallback(({ set }) => async (cards: TCard[]) => {
    const cardIds: TCardIds = [];
    const routeCardIds: { [key: string]: TRouteCardIds } = {};

    await Promise.all(
      cards.map((card: TCard) => {
        return registCard({ card, cardIds, routeCardIds });
      }),
    );

    set(cardIdsState, cardIds);
    await setSearchValuesIds(cardIds);
    Object.keys(routeCardIds).forEach((routeId: any) => {
      set(routeCardIdsState(routeId), routeCardIds[routeId]);
    });
  });

  type TArgs2 = {
    card: TCard;
    cardIds: TCardIds;
    routeCardIds: { [key: string]: TRouteCardIds };
  };
  const registCard = useRecoilCallback(({ set }) => async ({ card, cardIds, routeCardIds }: TArgs2) => {
    const newCard = { ...card };
    if (!newCard.total_id) newCard.total_id = "1";
    if (!newCard.display_id) newCard.display_id = "1";
    if (!newCard.vh_mode) newCard.vh_mode = "vt";
    set(cardState(newCard.uuid), newCard);
    cardIds.push(card.uuid);
    if (!routeCardIds[card.route_id]) routeCardIds[card.route_id] = [];
    routeCardIds[card.route_id].push(card.uuid);
    await setSearchValues(card, card.search_values);
  });

  return initCards;
};

export const useCards = () => {
  const { currentRoute } = useRouteStates();
  const { setSearchValues, getSearchValue } = useSearchValuesState2();

  const getAllCards = useRecoilCallback(({ snapshot }) => async () => {
    const cards: TCard[] = await snapshot.getPromise(allCardsSelector);
    return cards;
  });

  const saveCard = useRecoilCallback(({ set }) => (card: TCard) => {
    set(cardState(card.uuid), card);
  });

  const addNewCard = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        title: TCard["title"],
        cardMaster: TCardMaster,
        totalMaster: TTotalMaster,
        displayMaster: TDisplayMaster,
      ) => {
        const tmpCard = await addTmpCard(title, cardMaster, totalMaster, displayMaster);
        const newCard = await makePermTmpCard(tmpCard);
        return newCard;
      },
  );

  const addTmpCard = useRecoilCallback(
    ({ set }) =>
      async (
        title: TCard["title"],
        cardMaster: TCardMaster,
        totalMaster: TTotalMaster,
        displayMaster: TDisplayMaster,
        baseCard?: TCard,
      ) => {
        const newCard: TCard = {
          uuid: window.crypto.randomUUID(),
          route_id: currentRoute.id,
          card_master_id: cardMaster.id,
          total_id: totalMaster.id,
          display_id: displayMaster.id,
          title: title,
          card_title: cardMaster.title,
          vh_mode: displayMaster.vhMode,
          month: baseCard ? baseCard.month : undefined,
          is_tmp: true,
        };
        set(cardState(newCard.uuid), newCard);
        return newCard;
      },
  );

  const addCard = useRecoilCallback(({ snapshot, set }) => async (card: TCard, route: TRoute) => {
    const newCard = { ...card };
    newCard.uuid = window.crypto.randomUUID();
    newCard.route_id = route.id;
    newCard.is_tmp = false;
    const cardIds = [...(await snapshot.getPromise(cardIdsState))];
    const routeCardIds = [...(await snapshot.getPromise(routeCardIdsState(route.id)))];
    cardIds.push(newCard.uuid);
    routeCardIds.push(newCard.uuid);
    set(cardIdsState, cardIds);
    set(routeCardIdsState(route.id), routeCardIds);
    set(cardState(newCard.uuid), newCard);
    const newSearchValues = await getSearchValue(card);
    await setSearchValues(newCard, newSearchValues);
    return newCard;
  });

  const makePermTmpCard = useRecoilCallback(({ snapshot, set }) => async (card: TCard) => {
    const newCard = { ...card };
    newCard.is_tmp = false;
    const cardIds = [...(await snapshot.getPromise(cardIdsState))];
    const routeCardIds = [...(await snapshot.getPromise(routeCardIdsState(currentRoute.id)))];
    cardIds.push(newCard.uuid);
    routeCardIds.push(newCard.uuid);
    set(cardIdsState, cardIds);
    set(routeCardIdsState(currentRoute.id), routeCardIds);
    set(cardState(newCard.uuid), newCard);
    return newCard;
  });

  const copyCard = useRecoilCallback(({ snapshot, set, reset }) => async (card: TCard) => {
    const newCard = { ...card };
    newCard.uuid = window.crypto.randomUUID();
    newCard.is_tmp = false;
    const cardIds = [...(await snapshot.getPromise(cardIdsState))];
    const routeCardIds = [...(await snapshot.getPromise(routeCardIdsState(currentRoute.id)))];
    cardIds.push(newCard.uuid);
    routeCardIds.push(newCard.uuid);
    set(cardIdsState, cardIds);
    set(routeCardIdsState(currentRoute.id), routeCardIds);
    set(cardState(newCard.uuid), newCard);
    const newSearchValues = await getSearchValue(card);
    await setSearchValues(newCard, newSearchValues);
    return newCard;
  });

  const deleteCard = useRecoilCallback(({ snapshot, set, reset }) => async (card: TCard) => {
    const cardIds = [...(await snapshot.getPromise(cardIdsState))];
    const routeCardIds = [...(await snapshot.getPromise(routeCardIdsState(currentRoute.id)))];
    const newCardIds = cardIds.filter((id) => id !== card.uuid);
    const newRouteCardIds = routeCardIds.filter((id) => id !== card.uuid);
    set(cardIdsState, newCardIds);
    set(routeCardIdsState(currentRoute.id), newRouteCardIds);
    reset(cardState(card.uuid));
  });

  const deleteRouteCards = useRecoilCallback(({ snapshot, set, reset }) => async (route: TRoute) => {
    const cardIds = await snapshot.getPromise(cardIdsState);
    const routeCardIds = await snapshot.getPromise(routeCardIdsState(route.id));
    const newCardIds = cardIds.filter((id) => !routeCardIds.includes(id));
    set(cardIdsState, newCardIds);
    reset(routeCardIdsState(route.id));
  });

  return {
    getAllCards,
    saveCard,
    addNewCard,
    addTmpCard,
    makePermTmpCard,
    addCard,
    copyCard,
    deleteCard,
    deleteRouteCards,
  };
};

export const useCard = (uuid: TCard["uuid"]) => {
  return useRecoilValue(cardState(uuid));
};

export const useSetCard = (uuid: TCard["uuid"]) => {
  return useSetRecoilState(cardState(uuid));
};

export const useRouteCardIds = (routeId: TRouteId) => {
  return useRecoilValue(routeCardIdsState(String(routeId)));
};

export const useCardProps = (card: TCard) => {
  const kamokuList = card.kamoku_list && card.kamoku_list.length > 0 ? card.kamoku_list : [];
  const kamokuListMode = card.kamoku_list_mode ? card.kamoku_list_mode : "show";
  const kamokuSort = card.kamoku_sort && card.kamoku_sort.length > 0 ? card.kamoku_sort : [];
  const subfieldList = card.subfield_list && card.subfield_list.length > 0 ? card.subfield_list : ["JISSEKICHI"];
  const subfieldListMode = card.subfield_list_mode ? card.subfield_list_mode : "show";

  const fromY = card.month?.fromY ? String(card.month.fromY) : null;
  const fromM = card.month?.fromM ? String(card.month.fromM) : null;
  const fromYM = fromY && fromM ? fromY + fromM.padStart(2, "0") : null;
  const toY = card.month?.toY ? String(card.month.toY) : null;
  const toM = card.month?.toM ? String(card.month.toM) : null;
  const toYM = toY && toM ? toY + toM.padStart(2, "0") : null;
  const includeM = card.month?.includeM ? card.month.includeM : [];

  return {
    kamokuProps: { list: kamokuList, mode: kamokuListMode, len: kamokuList.length, sort: kamokuSort },
    subfieldProps: { list: subfieldList, mode: subfieldListMode, len: subfieldList.length },
    monthProps: { fromYM, toYM, includeM },
  };
};

export type TCardProps = ReturnType<typeof useCardProps>;
