import React, { FC, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { CockpitRoot } from "providers/CockpitRoot";
import ErrorBoundaryProvider from "providers/ErrorBoundaryProvider";

const App: FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: "always",
        retry: false,
        refetchOnWindowFocus: false,
        // suspense: true,
      },
    },
  });
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BrowserRouter>
            <ErrorBoundaryProvider>
              <Suspense fallback={<LinearProgress />}>
                <CockpitRoot />
              </Suspense>
            </ErrorBoundaryProvider>
          </BrowserRouter>
        </RecoilRoot>
      </QueryClientProvider>
    </div>
  );
};

export default App;
