import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useAlertPop } from "components/common/AlertPop";
import StdFormDialog from "components/common/form/StdFormDialog";
import { cardEditStateSelector } from "globalStates/cardEditState";
import { TNewCardPosition, useCardLayouts } from "models/CardLayoutModel";
import { TCardMaster, TDisplayMaster, TTotalMaster, useCardMasters, useDisplayMasters, useTotalMasters } from "models/CardMasterModel"; // prettier-ignore
import { cardOptionalSettings, TCard, useCards } from "models/CardModel";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { CardUtil } from "utilities";

const { openSelector, cardSelector } = cardEditStateSelector();

const fields: { [key: string]: string } = {
  cardMasterId: "表示セット",
  totalId: "集計単位",
  displayId: "表示モード",
};

const CardAddFormDialog = () => {
  const [openState, setOpenState] = useRecoilState(openSelector);
  const handleOnClose = useCallback(() => setOpenState(false), [setOpenState]);
  const [editTargetCard, setEditTargetCard] = useRecoilState(cardSelector);
  const { useCardTitle } = CardUtil;
  const cardTitle = useCardTitle(editTargetCard);
  const [editTitle, setEditTitle] = useState(false);

  const [cardMasterId, setCardMasterId] = useState<TCard["card_master_id"]>(undefined);
  const [totalId, setTotalId] = useState<TCard["total_id"]>(undefined);
  const [displayId, setDisplayId] = useState<TCard["display_id"]>(undefined);
  const [position, setPosition] = useState<TNewCardPosition>("top");
  const [priceScale, setPriceScale] = useState<TCard["price_scale"]>(1);
  const [optionalSettings, setOptionalSettings] = useState<TCard["optional_settings"]>(undefined);

  const { getCardMastersAll, getCardMaster } = useCardMasters();
  const { getTotalMastersAll, getTotalMaster } = useTotalMasters();
  const { getDisplayMastersAll, getDisplayMaster } = useDisplayMasters();
  const { saveCard, addNewCard } = useCards();
  const { addCardLayout } = useCardLayouts();

  const customHandleOnClose = useCallback(() => {
    // [START] comment outed for https://cips.cloud.redmine.jp/issues/17135
    // setCardMasterId(undefined);
    // setTotalId(undefined);
    // setDisplayId(undefined);
    // setPosition(undefined);
    // [END]
    handleOnClose();
    setTimeout(() => {
      setEditTargetCard(undefined);
      setEditTitle(false);
    }, 200);
  }, [handleOnClose, setEditTargetCard]);

  useEffect(() => {
    if (editTargetCard) {
      setCardMasterId(editTargetCard.card_master_id);
      setTotalId(editTargetCard.total_id);
      setDisplayId(editTargetCard.display_id);
      setPriceScale(editTargetCard.price_scale);
      setOptionalSettings(editTargetCard.optional_settings);
    }
  }, [editTargetCard]);

  const alertPop = useAlertPop();
  const alertError = useCallback(
    (message: string, event: any) => {
      alertPop.setAlert({
        message,
        severity: "error",
        anchor: event.nativeEvent.submitter,
      });
    },
    [alertPop],
  );

  const validate = useCallback(
    (event: any): boolean => {
      const empties = Object.entries({ cardMasterId, totalId, displayId })
        .filter(([k, v]) => !v)
        .map(([k, v]) => fields[k]);
      if (empties.length) {
        const message = `"${empties.join('" , "')}" を選択して下さい。`;
        alertError(message, event);
        return false;
      }
      return true;
    },
    [alertError, cardMasterId, displayId, totalId],
  );

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const title = data.get("title");
      if (validate(event) === false) return;
      if (!cardMasterId || !totalId || !displayId) return;
      const newCardMaster = getCardMaster(cardMasterId);
      const newTotalMaster = getTotalMaster(totalId);
      const newDisplayMaster = getDisplayMaster(displayId);
      if (editTargetCard) {
        const newCard = { ...editTargetCard };
        newCard.card_master_id = cardMasterId;
        newCard.total_id = totalId;
        newCard.display_id = displayId;
        newCard.vh_mode = newDisplayMaster.vhMode;
        newCard.title = title ? (title as string) : undefined;
        newCard.price_scale = priceScale;
        newCard.optional_settings = optionalSettings;
        if (editTargetCard.card_master_id !== newCard.card_master_id) {
          newCard.kamoku_list = undefined;
          newCard.subfield_list = undefined;
        }
        saveCard(newCard);
      } else {
        const newTitle = title ? (title as string) : undefined;
        const newCard = await addNewCard(newTitle, newCardMaster, newTotalMaster, newDisplayMaster);
        await addCardLayout(newCard, position);
      }
      customHandleOnClose();
    },
    // prettier-ignore
    [validate, cardMasterId, totalId, displayId, getCardMaster, getTotalMaster, getDisplayMaster, editTargetCard, customHandleOnClose, priceScale, optionalSettings, saveCard, addNewCard, addCardLayout, position],
  );

  const handleOnOptionalSettings = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const value = event.currentTarget.value as keyof typeof cardOptionalSettings;
    setOptionalSettings((oldList) => {
      if (oldList) {
        return checked ? [...oldList, value] : oldList.filter((k) => k !== value);
      } else {
        return checked ? [value] : undefined;
      }
    });
  }, []);

  return (
    <StdFormDialog
      title={editTargetCard ? "カード編集フォーム" : "カード追加フォーム"}
      openState={openState}
      handleOnSubmit={handleOnSubmit}
      handleOnClose={customHandleOnClose}
    >
      <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
        <FormLabel component="legend">タイトル</FormLabel>
        {editTitle ? (
          <>
            <TextField
              margin="normal"
              fullWidth
              id="title"
              label="Title"
              name="title"
              defaultValue={editTargetCard ? editTargetCard.title : null}
              size="small"
            />
            <Button onClick={() => setEditTitle(false)} size="small">
              キャンセル
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ ml: 2, mt: 1 }}>
              {editTargetCard && editTargetCard.title ? editTargetCard.title : cardTitle}
            </Typography>
            <Button onClick={() => setEditTitle(true)} size="small">
              タイトルを編集する
            </Button>
          </>
        )}
      </FormControl>

      <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
        <FormLabel component="legend">{fields["cardMasterId"]}</FormLabel>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {getCardMastersAll()
            .filter((cardMaster: TCardMaster) => cardMaster.mainFlag && cardMaster.mainFlag === "1")
            .map((cardMaster: TCardMaster) => (
              <Button
                key={cardMaster.id}
                variant={cardMasterId === cardMaster.id ? "contained" : "outlined"}
                onClick={() => setCardMasterId(cardMaster.id)}
                size="small"
                sx={{ m: 1 }}
              >
                {cardMaster.title}
              </Button>
            ))}
        </Box>
      </FormControl>

      <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
        <FormLabel component="legend">{fields["totalId"]}</FormLabel>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {getTotalMastersAll().map((totalMaster: TTotalMaster) => (
            <Button
              key={totalMaster.id}
              variant={totalId === totalMaster.id ? "contained" : "outlined"}
              onClick={() => setTotalId(totalMaster.id)}
              size="small"
              sx={{ m: 1 }}
            >
              {totalMaster.label}
            </Button>
          ))}
        </Box>
      </FormControl>

      <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
        <FormLabel component="legend">{fields["displayId"]}</FormLabel>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {getDisplayMastersAll().map((displayMaster: TDisplayMaster) => (
            <Button
              key={displayMaster.id}
              variant={displayId === displayMaster.id ? "contained" : "outlined"}
              onClick={() => setDisplayId(displayMaster.id)}
              size="small"
              sx={{ m: 1 }}
            >
              {displayMaster.label}
            </Button>
          ))}
        </Box>
      </FormControl>

      {editTargetCard && (
        <>
          <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
            <FormLabel component="legend">金額単位</FormLabel>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Button
                variant={priceScale === 1 ? "contained" : "outlined"}
                onClick={() => setPriceScale(1)}
                size="small"
                sx={{ m: 1 }}
              >
                一円単位
              </Button>
              <Button
                variant={priceScale === 1000 ? "contained" : "outlined"}
                onClick={() => setPriceScale(1000)}
                size="small"
                sx={{ m: 1 }}
              >
                千円単位
              </Button>
              <Button
                variant={priceScale === 1000000 ? "contained" : "outlined"}
                onClick={() => setPriceScale(1000000)}
                size="small"
                sx={{ m: 1 }}
              >
                百万円単位
              </Button>
            </Box>
          </FormControl>

          <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
            <FormLabel component="legend">追加設定</FormLabel>
            <FormGroup row>
              {(Object.keys(cardOptionalSettings) as Array<keyof typeof cardOptionalSettings>).map((key) => (
                <FormControlLabel
                  key={key}
                  name="key"
                  control={
                    <Checkbox
                      checked={optionalSettings?.includes(key) ?? false}
                      onChange={handleOnOptionalSettings}
                      size="small"
                    />
                  }
                  label={cardOptionalSettings[key]}
                  value={key}
                  componentsProps={{ typography: { variant: "body2" } }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </>
      )}

      {!editTargetCard && (
        <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
          <FormLabel component="legend">カード追加位置</FormLabel>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Button
              variant={position === "top" ? "contained" : "outlined"}
              onClick={() => setPosition("top")}
              size="small"
              sx={{ m: 1 }}
            >
              先頭
            </Button>
            <Button
              variant={position === "bottom" ? "contained" : "outlined"}
              onClick={() => setPosition("bottom")}
              size="small"
              sx={{ m: 1 }}
            >
              最後
            </Button>
          </Box>
        </FormControl>
      )}
    </StdFormDialog>
  );
};

export default CardAddFormDialog;
