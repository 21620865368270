import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useSearchValuesState2 } from "globalStates/cardSearchValuesState";
import { apiFetchUser, apiPatchUser, apiUploadUserAvatar } from "modules/Drupal/Drupal";
import { TCard } from "./CardModel";

type TUser = {
  uid: number;
  menus: [];
  routes: [];
  cards: [];
  layouts: {};
  firstName: string;
  lastName: string;
  avatar_file_id: number;
  avatar_url: string;
  availableFunctions: string[];
} | null;

const useUser = () => {
  const { data: fetchedUserData, refetch } = useSuspenseQuery({
    queryKey: ["userData"],
    queryFn: apiFetchUser,
  });

  const getUser = () => (fetchedUserData?.status === "success" ? fetchedUserData.user : null);
  const user = fetchedUserData?.status === "success" ? fetchedUserData.user : null;
  const { getAllSearchValues } = useSearchValuesState2();

  const patchUser = async (data: any) => {
    const newData = { ...data };
    if (newData.cards) {
      const allSearchValues = await getAllSearchValues();
      newData.cards = newData.cards.map(({ ...card }: TCard) => {
        const searchValues = allSearchValues.find((vals) => vals && vals.cardId === card.uuid);
        card.search_values = searchValues ? searchValues.values : {};
        return card;
      });
    }
    const result = await apiPatchUser(fetchedUserData?.raw, newData);
    return result;
  };

  const uploadUserAvatar = async (file: File) => {
    const result: any = await apiUploadUserAvatar(file);
    return result;
  };

  return { user, getUser, patchUser, refetchUser: refetch, uploadUserAvatar };
};

const useResetUser = () => {
  const queryClient = useQueryClient();
  const resetUser = () => {
    queryClient.clear();
  };
  return resetUser;
};

export type { TUser };

export { useUser, useResetUser };
