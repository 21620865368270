import { TCard } from "models/CardModel";
import { atom, selector } from "recoil";

// Atom
const openAtom = atom<boolean>({
  key: "CardEdit/Atom/open",
  default: false,
});
const cardAtom = atom<TCard | undefined>({
  key: "CardEdit/Atom/card",
  default: undefined,
});

// Selector
const openSelector = selector({
  key: "CardEdit/Selector/open",
  get: ({ get }) => get(openAtom),
  set: ({ set }, newBool) => set(openAtom, newBool),
});
const cardSelector = selector({
  key: "CardEdit/Selector/card",
  get: ({ get }) => get(cardAtom),
  set: ({ set }, newCard) => set(cardAtom, newCard),
});
export const cardEditStateSelector = () => {
  return {
    cardSelector,
    openSelector,
  };
};
