import CardAddFormDialog from "components/form/CardAddFormDialog";
import { AlertPop } from "./AlertPop";
import { CardSortKamokuFormDialog } from "components/form/CardSortKamokuFormDialog";

export const CommonTools = () => {
  return (
    <>
      <AlertPop />
      <CardAddFormDialog />
      <CardSortKamokuFormDialog />
    </>
  );
};
