import { TCard } from "models/CardModel";
import { atomFamily, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";

// Kamoku
const cardKamokuListAllState = atomFamily<any, TCard["uuid"]>({
  key: "cardKamokuListState",
  default: [],
});

export const useCardAllKamokuListState = (card: TCard) => {
  return useRecoilValue(cardKamokuListAllState(card.uuid));
};

export const useSetCardAllKamokuListState = (card: TCard) => {
  return useSetRecoilState(cardKamokuListAllState(card.uuid));
};

export const useCardKamokuState = () => {
  const getAllKamokuList = useRecoilCallback(
    ({ snapshot }) =>
      async (card: TCard) =>
        await snapshot.getPromise(cardKamokuListAllState(card.uuid)),
    [],
  );

  return { getAllKamokuList };
};

// Subfield
const cardSubfieldListAllState = atomFamily<any, TCard["uuid"]>({
  key: "cardSubfieldListAllState",
  default: [],
});

export const useCardAllSubfieldListState = (card: TCard) => {
  return useRecoilValue(cardSubfieldListAllState(card.uuid));
};

export const useSetCardAllSubfieldListState = (card: TCard) => {
  return useSetRecoilState(cardSubfieldListAllState(card.uuid));
};
