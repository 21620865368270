import { Button, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

const copy = (error: any) => {
  const arr: any[] = [];
  const rep = (key: any, value: any) => {
    if (value != null && typeof value == "object") {
      if (arr.indexOf(value) >= 0) return;
      arr.push(value);
    }
    return value;
  };
  let str = JSON.stringify(error, rep, 4);
  if (str === "{}") str = JSON.stringify(error, undefined, 4);
  navigator.clipboard.writeText(str);
  alert("エラー内容をクリップボードにコピーしました。");
};

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <>
      <Typography variant="h5" sx={{ color: "red" }}>
        Error
      </Typography>
      <Typography variant="body2">{error.message}</Typography>
      <Button onClick={() => copy(error)}>Copy Error</Button>
    </>
  );
};

const ErrorBoundaryProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryProvider;
