import { useState, MouseEvent } from "react";
import { Avatar, Button, Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { useUser } from "models/UserModel";
import { useAuth } from "models/AuthModel";
import AccountEditFormDialog from "components/form/AccountEditFormDialog";

const AccountMenu = () => {
  // menu open flag
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // edit user
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  // signout
  const { signOut } = useAuth();
  const onClickLogout = () => {
    (async () => {
      await signOut();
    })();
  };

  const { getUser } = useUser();
  const user = getUser();

  return !user ? (
    <></>
  ) : (
    <>
      <AccountEditFormDialog openState={editOpen} handleOnClose={handleEditClose} />
      <Button
        sx={{ ml: 1.5, color: "inherit" }}
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar src={user?.avatar_url} sx={{ width: 24, height: 24, mr: 1 }} />
        <Typography variant="body2" sx={{ textTransform: "none" }}>
          {user && `${user?.lastName} ${user?.firstName}`}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <MenuItem onClick={handleEditOpen}>
          <Avatar /> Edit
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={onClickLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>{" "}
    </>
  );
};

export default AccountMenu;
