import React, { useEffect } from "react";
import { useUser } from "models/UserModel";
// import { useInitMenus } from "models/MenuModel";
import { useInitRoutes } from "models/RouteModel";
import { useInitCards } from "models/CardModel";
import { useInitCardLayouts } from "models/CardLayoutModel";

const UserInitializer = ({ children }: { children: React.ReactElement }) => {
  const { getUser } = useUser();
  const user = getUser();

  // const initMenus = useInitMenus();
  const initRoutes = useInitRoutes();
  const initCards = useInitCards();
  const initCardLayouts = useInitCardLayouts();

  useEffect(() => {
    (async () => {
      const routes = await initRoutes(user);
      await initCards(user, routes);
      initCardLayouts(user, routes);
    })();
    // initMenus(user);
  }, [initCardLayouts, initCards, initRoutes, user]);

  return children;
};

export default UserInitializer;
