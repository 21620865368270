import StdFormDialog from "components/common/form/StdFormDialog";
import {
  useLeftNavItems,
  useMenuDeleteFormOpen,
  useMenuDeleteFormRoute,
  useSetMenuDeleteFormOpen,
} from "globalStates/leftNavState";
import { useCards } from "models/CardModel";
import { useRouteStates } from "models/RouteModel";
import { useCallback } from "react";

const MenuDeleteFormDialog = () => {
  const open = useMenuDeleteFormOpen();
  const setOpen = useSetMenuDeleteFormOpen();
  const route = useMenuDeleteFormRoute();
  const { deleteItem } = useLeftNavItems();
  const { deleteRouteCards } = useCards();
  const { currentRoute, navigate2Top } = useRouteStates();

  const handleOnClose = useCallback(() => setOpen(false), [setOpen]);

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      await deleteItem(route);
      await deleteRouteCards(route);
      currentRoute && route.id === currentRoute.id && navigate2Top();
      handleOnClose();
    },
    [currentRoute, deleteItem, deleteRouteCards, handleOnClose, navigate2Top, route],
  );

  if (!route) return <></>;

  return (
    <StdFormDialog
      title="Delete Menu Form"
      text={`Are you sure you want to delete the Menu "${route.title}" ?`}
      openState={open}
      handleOnSubmit={handleOnSubmit}
      handleOnClose={handleOnClose}
    />
  );
};

export default MenuDeleteFormDialog;
