import { useCardMasters, useDisplayMasters, useTotalMasters } from "models/CardMasterModel";
import { TCard } from "models/CardModel";

type TIsChartable = (card: TCard) => boolean;
const isChartable: TIsChartable = (card) => {
  return (
    card.kamoku_list?.length === 1 &&
    card.kamoku_list_mode === "show" &&
    card.subfield_list?.length === 1 &&
    card.subfield_list_mode === "show"
  );
};

const useCardTitle = (card: TCard | undefined) => {
  const { getCardMaster } = useCardMasters();
  const { getTotalMaster } = useTotalMasters();
  const { getDisplayMaster } = useDisplayMasters();

  if (!card) return null;
  const titleAry = [];
  titleAry.push(getCardMaster(card.card_master_id).title);
  card.total_id && titleAry.push(getTotalMaster(card.total_id).label);
  card.display_id && titleAry.push(getDisplayMaster(card.display_id).label);

  return titleAry.join(" ");
};

// [ToDo] このメソッドを新規に作ったので、他の場所で使える場所が有るはず。
const showFieldsList = (card: TCard, type: "kamoku" | "subfield", allList: any) => {
  const settings = {
    list: type === "kamoku" ? card.kamoku_list : card.subfield_list,
    mode: type === "kamoku" ? card.kamoku_list_mode : card.subfield_list_mode,
  };
  return Object.keys(allList).filter((k) => {
    const bool = settings.list?.includes(k);
    return settings.mode === "show" ? bool : !bool;
  });
};

export const CardUtil = { isChartable, useCardTitle, showFieldsList };
