import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { TUser } from "./UserModel";
import { TCard } from "./CardModel";
import { TRouteCardLayouts } from "./CardLayoutModel";
import { useNavigate } from "react-router-dom";

export type TRoute = {
  id: string;
  type?: "route" | "folder" | "shared_page";
  depth?: number;
  parentId?: string | undefined;
  hasChildren?: boolean;
  title?: string | undefined;
  url?: string | undefined;
  page?: string | undefined;
  icon?: string;
  children?: TRoute[];
  menuType?: "fixed" | undefined;
  sharedPageId?: string;
  sharedPageContents?: {
    cards: TCard[];
    layouts: TRouteCardLayouts;
  };
};

const routeState = atomFamily<TRoute, TRoute["id"]>({
  key: "routeState",
  default: undefined,
});

const currentRouteState = atom<TRoute>({
  key: "currentRouteState",
  default: undefined,
});

export type TRouteIds = TRoute["id"][];
const routeIdsState = atom<TRouteIds>({
  key: "routeIdsState",
  default: [],
});

export const allRoutesSelector = selector<TRoute[]>({
  key: "allRoutesSelector",
  get: ({ get }) => {
    const ids = get(routeIdsState);
    return ids.map((routeId) => get(routeState(routeId)));
  },
});

const fixedRoutes: TRoute[] = [
  {
    id: "1",
    icon: "Home",
    page: "Home",
    title: "Home",
    type: "route",
    url: "/",
    menuType: "fixed",
  },
];

export const useInitRoutes = () => {
  const initRoutes = useRecoilCallback(({ set }) => async (user: TUser) => {
    const routesBase = user?.routes.filter((r: TRoute) => r.id !== "1"); // [暫定][暫定処置]
    // [ToDo] ↓ 最終的にはサーバから取得したい
    const routes = !routesBase || routesBase.length === 0 ? fixedRoutes : [...fixedRoutes, ...routesBase];
    await registRoutes(routes);
    const currentRoute = routes ? routes.find((route) => route.url === window.location.pathname) : undefined;
    currentRoute && set(currentRouteState, currentRoute);
    return routes;
  });

  const registRoutes = useRecoilCallback(({ set }) => async (routes: TRoute[]) => {
    const routeIds: TRouteIds = routes.map((r) => r.id);
    const newRoutes = routes.map((r) => {
      // 親フォルダ不在の子供達対応
      if (r.parentId && !routeIds.includes(r.parentId)) {
        delete r.depth;
        delete r.parentId;
      }
      return r;
    });
    for (const route of newRoutes) await registRoute({ route });
    set(routeIdsState, routeIds);
  });

  type TArgs = {
    route: TRoute;
  };
  const registRoute = useRecoilCallback(({ set }) => async ({ route }: TArgs) => {
    set(routeState(route.id), route);
  });

  return initRoutes;
};

export const useRouteStates = () => {
  const allRoutes = useRecoilValue(allRoutesSelector);
  const routeIds = useRecoilValue(routeIdsState);
  const currentRoute = useRecoilValue(currentRouteState);
  const setCurrentRoute = useSetRecoilState(currentRouteState);
  const navigate = useNavigate();

  const setRoute = useRecoilCallback(({ set }) => (route: TRoute) => {
    set(routeState(route.id), route);
  });

  const setRouteIds = useRecoilCallback(({ set }) => (routeIds: TRoute["id"][]) => {
    set(routeIdsState, routeIds);
  });

  const navigate2Top = useRecoilCallback(({ set }) => () => {
    const topRoute = fixedRoutes.find((route) => route.url === "/");
    topRoute && set(currentRouteState, topRoute);
    navigate("/");
  });

  return { allRoutes, routeIds, currentRoute, setRoute, setRouteIds, setCurrentRoute, navigate2Top };
};
