import { grey } from "@mui/material/colors";
import { TCard, TCardProps } from "models/CardModel";

const tableSx = {
  borderCollapse: "separate",
  borderBottom: "1px solid",
  borderRight: "1px solid",
  borderColor: grey[400],
  tbody: {
    tr: {
      "&:hover": {
        td: {
          backgroundColor: grey[100],
        },
      },
    },
  },
};

const tdSx = {
  padding: 0.5,
  whiteSpace: "nowrap",
  border: 1,
  borderColor: grey[400],
  backgroundColor: "white",
  borderWidth: "1px 0 0 1px",
};

const getThNamesBySettings = (settings: any) => {
  return {
    ...settings.targets,
    ...settings.children,
    // その他
    KANRISIHYO_NAME: "管理指標",
  };
};

const numFormatter = new Intl.NumberFormat("ja-JP");
const priceScaleSigns = {
  1: undefined,
  1000: "K",
  1000000: "M",
};
const priceScaleExcludeNames = ["単価", "食数", "人数", "者数", "時間"];
const cellValueFormatter = (value: any, format: string, priceScale?: TCard["price_scale"], sihyoName?: string) => {
  if (!value) return value;
  let newValue = value;
  let result = "";
  let style: { [key: string]: any } = {};
  switch (format) {
    case "number_format":
      if (Number(newValue) < 0) style["color"] = "red";
      let priceScaleSign: any = undefined;
      if (priceScale && priceScale > 1 && sihyoName) {
        let flag = true;
        priceScaleExcludeNames.forEach((str) => {
          if (flag && sihyoName.indexOf(str) >= 0) flag = false;
        });
        if (flag) {
          newValue = value / priceScale;
          priceScaleSign = priceScaleSigns[priceScale];
        }
      }
      result = numFormatter.format(Number(Math.trunc(newValue))) + (priceScaleSign ? `(${priceScaleSign})` : "");
      break;
    case "percentage":
      if (Number(newValue) < 0) style["color"] = "red";
      result = Number(newValue).toFixed(1);
      break;
    default:
      if (Number(newValue) < 0) style["color"] = "red";
      result = newValue;
  }
  return <span style={style}>{String(result)}</span>;
};

type TIsOutOfDate = (ym: string, cardProps: TCardProps) => boolean;
const isOutOfDate: TIsOutOfDate = (ym, cardProps) => {
  const { monthProps } = cardProps;
  if (
    (monthProps.fromYM && Number(ym) < Number(monthProps.fromYM)) ||
    (monthProps.toYM && Number(ym) > Number(monthProps.toYM))
  ) {
    return true;
  }
  const m = Number(ym.slice(-2));
  if (monthProps.includeM.length > 0 && !monthProps.includeM.includes(m)) {
    return true;
  }
  return false;
};

type TFieldFilter = (type: "kamoku" | "subfield", key: any, cardProps: any) => boolean;
const fieldFilter: TFieldFilter = (type, key, cardProps) => {
  const { kamokuProps, subfieldProps } = cardProps;
  const props = type === "kamoku" ? kamokuProps : type === "subfield" ? subfieldProps : null;
  if (!props || props.list.length === 0) return true;
  const bool = props.list.includes(key);
  return props.mode === "show" ? bool : !bool;
};

type TFieldSort = (a: [any, any], b: [any, any], kamokuProps: any) => number;
const fieldSort: TFieldSort = (a, b, kamokuProps) => {
  if (kamokuProps.sort.length === 0) return 1;
  const [aK, aV] = a;
  const [bK, bV] = b;
  if (aV["CODE"] && bV["CODE"]) {
    if (aV["CODE"] > bV["CODE"]) return 1;
    if (aV["CODE"] < bV["CODE"]) return -1;
  }
  const aKamokuId = aK.indexOf("_") > 0 ? aK.split("_")[1] : aK;
  const bKamokuId = bK.indexOf("_") > 0 ? bK.split("_")[1] : bK;
  return kamokuProps.sort.indexOf(aKamokuId) > kamokuProps.sort.indexOf(bKamokuId) ? 1 : -1;
};

type TTableSort = (a: any, b: any, card: TCard, sortKey: any, sortOrder: any) => number;
const tableSort: TTableSort = (a, b, card, sortKey, sortOrder) => {
  if (!sortKey) return 0;

  const getValue = (ab: any) => {
    const [, row] = ab;
    if (card.vh_mode === "vt") {
      const [ym, subfield] = sortKey;
      return row[ym].children[subfield];
    } else {
      const [ym, kamoku, subfield] = sortKey;
      return row[ym][kamoku].children[subfield];
    }
  };

  const valueA = Number(getValue(a));
  const valueB = Number(getValue(b));
  if (valueA === valueB) return 0;
  if (sortOrder === "asc") return valueA > valueB ? 1 : -1;
  else return valueA < valueB ? 1 : -1;
};

type TIsSortable = (card: TCard) => boolean;
const isSortable: TIsSortable = (card) => {
  if (card.vh_mode === "hn") return true;
  return card.kamoku_list?.length === 1 && card.kamoku_list_mode === "show";
};

const makeColsSticky = (table: HTMLTableElement) => {
  const rows = table.getElementsByTagName("tr");
  Object.values(rows).forEach((row) => {
    let left = 0;
    let last: any = null;
    Object.values(row.getElementsByClassName("stickyCell")).forEach((c) => {
      const cell = c as HTMLElement;
      const rect = cell.getBoundingClientRect();
      cell.style.position = "sticky";
      cell.style.zIndex = "1";
      cell.style.left = left + "px";
      cell.style.width = rect.width + "px";
      left += rect.width;
      last = cell;
    });
    if (last) last.style.borderRight = "1px solid #bdbdbd";
  });
};

export const TableUtil = {
  tableSx,
  tdSx,
  cellValueFormatter,
  isOutOfDate,
  fieldFilter,
  fieldSort,
  tableSort,
  isSortable,
  getThNamesBySettings,
  makeColsSticky,
};
