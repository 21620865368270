import { apiPostSharePage } from "modules/Drupal/Drupal";

export type TSharePage = {
  title?: string;
  uid: number;
  routeId: string;
};

export const useSharePage = () => {
  const post = async (data: TSharePage) => {
    return await apiPostSharePage(data);
  };

  return { post };
};
