import React, { FC, Suspense } from "react";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import ErrorBoundaryProvider from "providers/ErrorBoundaryProvider";

const MinimalLayout: FC<{ children: React.ReactElement | null }> = ({ children }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ErrorBoundaryProvider>
          <Suspense fallback={<div>Loading...(MinimalLayout)</div>}>{children ? children : <Outlet />}</Suspense>
        </ErrorBoundaryProvider>
      </Box>
    </Container>
  );
};

export default MinimalLayout;
