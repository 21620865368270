import { Box, Button, FormControl, FormLabel } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { apiFetchSharedPages } from "modules/Drupal/Drupal";
import { atom, useRecoilState } from "recoil";

const useFetchSharedPages = () => {
  return useSuspenseQuery({
    queryKey: ["useFetchSharedPages"],
    queryFn: apiFetchSharedPages,
  });
};

type TSharedPage = {
  id: string;
  createUserId: string;
  routeId: string;
  title: string;
};

export const selectedSharedPageAtom = atom<TSharedPage>({
  key: "SharedPageList/SelectedPage/Atom",
  default: undefined,
});

export const SharedPageList = () => {
  const { data: pages } = useFetchSharedPages();
  const [selectedPage, selectPage] = useRecoilState(selectedSharedPageAtom);

  return (
    <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2, display: "block" }}>
      <FormLabel component="legend">共有ページ</FormLabel>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {pages.map((sharedPage: TSharedPage) => (
          <Button
            key={sharedPage.id}
            variant={selectedPage && selectedPage.id === sharedPage.id ? "contained" : "outlined"}
            onClick={() => selectPage(sharedPage)}
            sx={{ m: 1, textTransform: "none" }}
          >
            {sharedPage.title}
          </Button>
        ))}
      </Box>
    </FormControl>
  );
};
