import { useSuspenseQuery } from "@tanstack/react-query";
import {
  apiFetchCardMasters,
  apiFetchDisplayMasters,
  apiFetchFieldMasters,
  apiFetchTotalMasters,
} from "modules/Drupal/Drupal";

// CardMaster
export type TCardMaster = {
  id: any;
  title: string;
  api: string;
  cardType: string;
  mainFlag?: string;
  viewTypes: string[];
  searchInfo?: any;
  searchList: any[];
};

export const useCardMasters = () => {
  const { data } = useFetchCardMasters();

  const getCardMastersAll = () => data;

  const getCardMaster = (id: TCardMaster["id"]): TCardMaster => data.find((master: TCardMaster) => master.id === id);

  const getCardMasters = (ids: TCardMaster["id"][]): TCardMaster[] => ids.map((id) => getCardMaster(id));

  return { getCardMaster, getCardMasters, getCardMastersAll };
};

const useFetchCardMasters = () => {
  return useSuspenseQuery({
    queryKey: ["useFetchCardMasters"],
    queryFn: apiFetchCardMasters,
  });
};

// TotalMaster
export type TTotalMaster = {
  id: string;
  label: string;
  searchList: any[];
};

export const useTotalMasters = () => {
  const { data } = useFetchTotalMasters();

  const getTotalMastersAll = () => data;

  const getTotalMaster = (id: TTotalMaster["id"]) => data.find((master: TTotalMaster) => master.id === id);

  const getTotalMasters = (ids: TTotalMaster["id"][]) => ids.map((id) => getTotalMaster(id));

  return { getTotalMaster, getTotalMasters, getTotalMastersAll };
};

const useFetchTotalMasters = () => {
  return useSuspenseQuery({
    queryKey: ["useFetchTotalMasters"],
    queryFn: apiFetchTotalMasters,
  });
};

// DisplayMaster
export type TDisplayMaster = {
  id: string;
  label: string;
  vhMode: "vt" | "hn";
};

export const useDisplayMasters = () => {
  const { data } = useFetchDisplayMasters();

  const getDisplayMastersAll = () => data;

  const getDisplayMaster = (id: TDisplayMaster["id"]) => data.find((master: TDisplayMaster) => master.id === id);

  const getDisplayMasters = (ids: TDisplayMaster["id"][]) => ids.map((id) => getDisplayMaster(id));

  return { getDisplayMaster, getDisplayMasters, getDisplayMastersAll };
};

const useFetchDisplayMasters = () => {
  return useSuspenseQuery({
    queryKey: ["useFetchDisplayMasters"],
    queryFn: apiFetchDisplayMasters,
  });
};

// FieldMaster
export type TFieldMaster = {
  id: string;
  label: string;
  drill_through_target: string[];
};

export const useFieldMasters = () => {
  const { data } = useFetchFieldMasters();

  const getFieldMastersAll = (): TFieldMaster[] => data;

  const getFieldMaster = (id: TFieldMaster["id"]): TFieldMaster =>
    data.find((master: TFieldMaster) => master.id === id);

  const getFieldMasters = (ids: TFieldMaster["id"][]): TFieldMaster[] => ids.map((id) => getFieldMaster(id));

  return { getFieldMaster, getFieldMasters, getFieldMastersAll };
};

const useFetchFieldMasters = () => {
  return useSuspenseQuery({
    queryKey: ["useFetchFieldMasters"],
    queryFn: apiFetchFieldMasters,
  });
};
