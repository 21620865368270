import { FC, useEffect, useRef, useState } from "react";
import { Box, Drawer, Toolbar, useTheme } from "@mui/material";

import { drawerWidth, editModeDrawerWidth } from "config";
import { useLeftNavIsEditMode, useLeftNavOpen } from "globalStates/leftNavState";
import LeftNavContent from "./LeftNavContent";
import LeftNavBottom from "./LeftNavBottom";
import useHeaderHeight from "globalStates/headerHeightState";
import { useRouteStates } from "models/RouteModel";

const LeftNav: FC = () => {
  const theme = useTheme();
  const { navigate2Top } = useRouteStates();

  const { isOpen } = useLeftNavOpen();
  const isEditMode = useLeftNavIsEditMode();

  const { headerHeight } = useHeaderHeight();
  const [simpleBarHeight, setSimpleBarHeight] = useState(window.innerHeight - headerHeight);
  const wrapBottom = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const setHeight = () => {
      wrapBottom.current && setSimpleBarHeight(window.innerHeight - headerHeight - wrapBottom.current.clientHeight);
    };
    setHeight();
    window.addEventListener("resize", setHeight);
    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, [headerHeight]);

  return (
    <Box component="nav" sx={{ flexShrink: 0 }}>
      <Drawer
        variant="persistent"
        open={isOpen}
        sx={{
          width: isOpen ? (isEditMode ? editModeDrawerWidth : drawerWidth) : 0,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: isEditMode ? editModeDrawerWidth : drawerWidth,
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Toolbar
            sx={{
              cursor: "pointer",
              paddingLeft: "16px !important",
              "&:hover": { backgroundColor: "grey.200" },
            }}
            onClick={() => navigate2Top()}
          >
            <div style={{ height: "90%", marginTop: "10%" }}>
              <img src="/static/cockpit_logo_s.png" alt="COCKPIT" style={{ maxWidth: "100%", maxHeight: "100%" }} />
            </div>
            {/* <Typography component="h1" variant="h6" noWrap>
              COCKPIT
            </Typography> */}
          </Toolbar>
          <LeftNavContent simpleBarHeight={simpleBarHeight} />
          <div ref={wrapBottom}>
            <LeftNavBottom />
          </div>
        </Box>
      </Drawer>
    </Box>
  );
};

export default LeftNav;
