import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { Box, Divider, FormControl, FormLabel, Grid, Paper, styled } from "@mui/material";
import StdFormDialog from "components/common/form/StdFormDialog";
import { useCardKamokuState } from "globalStates/cardKamokuState";
import { TCard, useCards } from "models/CardModel";
import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { CSS } from "@dnd-kit/utilities";

export const sortKamokuCardAtom = atom<TCard>({
  key: "CardSortKamokuFormDialog/Atom/card",
  default: undefined,
});

export const sortKamokuOpenAtom = atom<boolean>({
  key: "CardSortKamokuFormDialog/Atom/open",
  default: false,
});

export const CardSortKamokuFormDialog = () => {
  const [open, setOpen] = useRecoilState(sortKamokuOpenAtom);
  const card = useRecoilValue(sortKamokuCardAtom);
  const resetCard = useResetRecoilState(sortKamokuCardAtom);
  const [list, setList] = useState<any[]>([]);
  const { saveCard } = useCards();

  const { getAllKamokuList } = useCardKamokuState();

  const handleOnClose = useCallback(() => {
    setOpen(false);
    resetCard();
  }, [resetCard, setOpen]);

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const newCard = { ...card };
      newCard.kamoku_sort = list.map((item) => item.id);
      saveCard(newCard);
      handleOnClose();
    },
    [card, handleOnClose, list, saveCard],
  );

  const handleOnReset = useCallback(() => {
    const newCard = { ...card };
    newCard.kamoku_sort = undefined;
    saveCard(newCard);
    handleOnClose();
  }, [card, handleOnClose, saveCard]);

  useEffect(() => {
    (async () => {
      if (!card) return;
      const allKamokuList = await getAllKamokuList(card);
      const list = card.kamoku_sort
        ? card.kamoku_sort.map((id) => ({ id, value: allKamokuList[id] }))
        : Object.entries(allKamokuList).map(([key, value]) => ({ id: key, value }));
      setList(list);
    })();
  }, [card, getAllKamokuList]);

  const handleOnDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setList((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  if (!card) return <></>;

  return (
    <StdFormDialog
      title="表示科目 並べ替え"
      openState={open}
      handleOnSubmit={handleOnSubmit}
      handleOnReset={handleOnReset}
      handleOnClose={handleOnClose}
    >
      <Divider />

      <FormControl component="fieldset" fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
        <FormLabel component="legend">科目 並び順</FormLabel>
        <div>
          <DndContext onDragEnd={handleOnDragEnd}>
            <SortableContext items={list}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {list.map((kamoku, index) => (
                  <SortableItem key={kamoku.id} id={kamoku.id} label={kamoku.value} index={index} />
                ))}
              </Grid>
            </SortableContext>
          </DndContext>
        </div>
      </FormControl>
    </StdFormDialog>
  );
};

const SortableItem = ({ id, label, index }: { id: any; label: string; index: number }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid
      item
      xs={3}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{ zIndex: isDragging ? 1 : "unset", cursor: isDragging ? "grabbing" : "grab" }}
    >
      <Item>
        <Box sx={{ display: "flex", textAlign: "left" }}>
          <Box sx={{ width: "20%" }}>{index + 1}.</Box>
          <Box>{label}</Box>
        </Box>
      </Item>
    </Grid>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));
