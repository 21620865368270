import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Box, Toolbar, useTheme } from "@mui/material";
import Header from "./Header";
import LeftNav from "./LeftNav";
import ErrorBoundaryProvider from "providers/ErrorBoundaryProvider";

const MainLayout = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      <LeftNav />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          [theme.breakpoints.up("md")]: {
            p: 1,
          },
        }}
      >
        <Toolbar />
        <ErrorBoundaryProvider>
          <Suspense fallback={<div>Loading...(MainLayout)</div>}>
            <Outlet />
          </Suspense>
        </ErrorBoundaryProvider>
      </Box>
    </Box>
  );
};

export default MainLayout;
