import { FC } from "react";
import { CustomThemeProvider } from "./CustomThemeProvider";
import AuthProvider from "./AuthProvider";
import Router from "./Router";
import UserInitializer from "./UserInitializer";
import { CommonTools } from "components/common/CommonTools";

export const CockpitRoot: FC = () => {
  return (
    <CustomThemeProvider>
      <AuthProvider>
        <UserInitializer>
          <>
            <Router />
            <CommonTools />
          </>
        </UserInitializer>
      </AuthProvider>
    </CustomThemeProvider>
  );
};
