import React, { useCallback, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import { useLeftNavItems, useLeftNavOpenFolders } from "globalStates/leftNavState";
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { TRoute, useRouteStates } from "models/RouteModel";
import DndLeftNavItemSortable from "./DndLeftNavItemSortable";
import DndLeftNavItem from "./DndLeftNavItem";
import Icons from "components/common/Icons";
import { useNavigate } from "react-router-dom";

const LeftNavMenu = () => {
  const { fixedItems, userItems, setItem, setItemsOnDragEnd, closeAllFolder, setItemsOnCollapse } = useLeftNavItems();
  const [activeId, setActiveId] = useState<TRoute["id"] | null>(null);
  const activeItem = userItems.find((item) => item.id === activeId);
  const { isOpen: isOpenParent } = useLeftNavOpenFolders();

  const navigate = useNavigate();
  const { setCurrentRoute, currentRoute } = useRouteStates();

  const handleOnDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      if (userItems.find((route) => route.id === String(active.id))?.type === "folder") {
        closeAllFolder();
      }
      setActiveId(String(active.id));
    },
    [closeAllFolder, userItems],
  );

  const handleOnDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveId(null);
      unsetBackgrounds();
      const { active, over } = event;
      active && over && setItemsOnDragEnd(String(active.id), String(over.id));
    },
    [setItemsOnDragEnd],
  );

  const handleOnDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over, collisions } = event;
      if (!over || !over.data.current || !active.data.current || !collisions) return;
      const overItem = userItems.find((item) => item.id === over.id);
      if (overItem?.type === "folder") {
        setItemsOnCollapse(overItem.id, "open");
      }
    },
    [setItemsOnCollapse, userItems],
  );

  const handleOnDragMove = useCallback(
    (event: DragMoveEvent) => {
      const { active, over, collisions } = event;
      if (!over || !over.data.current || !active.data.current || !collisions) return;

      const activeItemOrg = userItems.find((item) => item.id === active.id);
      const activeItem = { ...activeItemOrg } as TRoute;
      const overItem = userItems.find((item) => item.id === over.id);
      if (!activeItem || !overItem || (activeItem.type !== "route" && activeItem.type !== "shared_page")) return;

      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.data.current.sortable.index;
      const overItemIds = over.data.current.sortable.items;
      const prevItemId = activeIndex < overIndex ? overItem.id : overItemIds[overIndex - 1];
      const nextItemId = activeIndex <= overIndex ? overItemIds[overIndex + 1] : overItem.id;
      const prevItem = userItems.find((item) => item.id === prevItemId);
      const nextItem = userItems.find((item) => item.id === nextItemId);
      const prevCollision = prevItem ? collisions.find((c) => c.id === prevItem.id) : null;
      const prevDist = prevCollision && prevCollision.data ? Number(prevCollision.data.value) : 0;

      if (!prevItem) {
        activeItem["depth"] = 0;
        activeItem["parentId"] = undefined;
      } else if (nextItem && nextItem.parentId) {
        activeItem["depth"] = 1;
        activeItem["parentId"] = nextItem.parentId;
      } else if (!nextItem && prevDist < 5) {
        activeItem["depth"] = 0;
        activeItem["parentId"] = undefined;
      } else if (prevItem && prevItem.type === "folder" && isOpenParent(prevItem.id)) {
        const dist = activeIndex <= overIndex ? 30 : 5;
        if (prevDist < dist) {
          activeItem["depth"] = 1;
          activeItem["parentId"] = prevItem.id;
        } else {
          activeItem["depth"] = 0;
          activeItem["parentId"] = undefined;
        }
      } else if (
        prevItem &&
        (prevItem.type === "route" || prevItem.type === "shared_page") &&
        prevItem.parentId &&
        prevDist < 30
      ) {
        activeItem["depth"] = 1;
        activeItem["parentId"] = prevItem.parentId;
      } else {
        activeItem["depth"] = 0;
        activeItem["parentId"] = undefined;
      }

      unsetBackgrounds();
      if (activeItem.parentId) {
        const parentElem = document
          .getElementById(activeItem.parentId)
          ?.closest(".DndLeftNavItemSortable") as HTMLElement;
        if (parentElem) parentElem.style.backgroundColor = "rgb(237 108 2 / 12%)";
      }

      if (activeItemOrg?.parentId !== activeItem.parentId) setItem(activeItem);
    },
    [isOpenParent, setItem, userItems],
  );

  const unsetBackgrounds = () => {
    document.querySelectorAll<HTMLInputElement>(".DndLeftNavItemSortable").forEach((elem) => {
      elem.style.backgroundColor = "unset";
    });
  };

  return (
    <>
      <List component="nav">
        <ListSubheader component="div" id="menu_kotei">
          固定メニュー
        </ListSubheader>
        {fixedItems.map((item) => (
          <ListItemButton
            key={item.id}
            dense
            onClick={() => {
              item.url && navigate(item.url);
              setCurrentRoute(item);
            }}
            selected={currentRoute && currentRoute.id === item.id}
          >
            {item.icon && (
              <ListItemIcon>
                <Icons iconName={item.icon} />
              </ListItemIcon>
            )}
            <ListItemText>{item.title}</ListItemText>
          </ListItemButton>
        ))}
      </List>
      <Divider />
      {userItems && (
        <List component="nav">
          <ListSubheader component="div" id="menu_free">
            自由メニュー
          </ListSubheader>
          <div>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToParentElement]}
              onDragStart={handleOnDragStart}
              onDragEnd={handleOnDragEnd}
              onDragOver={handleOnDragOver}
              onDragMove={handleOnDragMove}
            >
              <SortableContext items={userItems}>
                {userItems.map((item) => {
                  const id = `${item.type}_${item.id}`;
                  switch (item.type) {
                    case "folder":
                    case "route":
                    case "shared_page":
                      return <DndLeftNavItemSortable key={id} item={item as TRoute} />;
                    default:
                      return false;
                  }
                })}
              </SortableContext>
              <DragOverlay>{activeItem && <DndLeftNavItem item={activeItem as TRoute} isOverlay />}</DragOverlay>
            </DndContext>
          </div>
        </List>
      )}
    </>
  );
};

export default LeftNavMenu;
