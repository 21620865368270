import { useCallback } from "react";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Alert, AlertProps, Popover, PopoverProps } from "@mui/material";

type TProps = {
  message: string;
  severity?: AlertProps["severity"];
  anchor?: HTMLElement;
  anchorOrigin?: PopoverProps["anchorOrigin"];
  transformOrigin?: PopoverProps["transformOrigin"];
};

const anchorAtom = atom<undefined | HTMLElement>({
  key: "AlertPop/Atom/anchor",
  default: undefined,
});

const propsAtom = atom<null | TProps>({
  key: "AlertPop/Atom/props",
  default: null,
});

const openAtom = atom<boolean>({
  key: "AlertPop/Atom/open",
  default: false,
});

export const useAlertPop = () => {
  const setProps = useSetRecoilState(propsAtom);
  const setAnchor = useSetRecoilState(anchorAtom);
  const setOpen = useSetRecoilState(openAtom);
  const setAlert = useCallback(
    (props: TProps) => {
      setProps(props);
      setAnchor(props.anchor);
      setOpen(true);
    },
    [setAnchor, setOpen, setProps],
  );

  const alertError = useCallback(
    (message: string, event: any) => {
      setAlert({
        message,
        severity: "error",
        anchor: event.nativeEvent.submitter,
      });
    },
    [setAlert],
  );

  return { setAlert, alertError };
};

export const AlertPop = () => {
  const [anchor, setAnchor] = useRecoilState(anchorAtom);
  const [open, setOpen] = useRecoilState(openAtom);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    setAnchor(undefined);
  }, [setAnchor, setOpen]);

  const props = useRecoilValue(propsAtom);
  if (!props) return <></>;
  const { message, severity, anchorOrigin, transformOrigin } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={handleOnClose}
      anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "left" }}
      transformOrigin={transformOrigin ? transformOrigin : { vertical: "bottom", horizontal: "right" }}
    >
      <Alert variant="filled" severity={severity ? severity : "success"} sx={{ px: 1, py: 0 }}>
        {message}
      </Alert>
    </Popover>
  );
};
