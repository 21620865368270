import { Button, FormControl, FormGroup, FormLabel, LinearProgress, Link, TextField, Typography } from "@mui/material";
import StdFormDialog from "components/common/form/StdFormDialog";
import Icons from "components/common/Icons";
import {
  useLeftNavItems,
  useMenuEditFormMode,
  useMenuEditFormOpen,
  useMenuEditFormRoute,
  useSetMenuEditFormOpen,
} from "globalStates/leftNavState";
import { defaultLayouts, TRouteCardLayouts, useCardLayouts } from "models/CardLayoutModel";
import { TRoute, useRouteStates } from "models/RouteModel";
import { useUser } from "models/UserModel";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectedSharedPageAtom, SharedPageList } from "./elements/SharedPageList";
import { useRecoilValue } from "recoil";
import { apiFetchSharedPage } from "modules/Drupal/Drupal";
import { useCards } from "models/CardModel";
import { useAlertPop } from "components/common/AlertPop";
import ErrorBoundaryProvider from "providers/ErrorBoundaryProvider";
import { useSearchValuesState2 } from "globalStates/cardSearchValuesState";

const menuIcons = ["Dashboard", "Article", "Inbox"];

type TType = "route" | "folder" | "shared_page";

const MenuEditFormDialog = () => {
  const [typeState, setType] = useState<TType>("route");
  const [iconState, setIcon] = useState<string>("Dashboard");
  const open = useMenuEditFormOpen();
  const mode = useMenuEditFormMode();
  const setOpen = useSetMenuEditFormOpen();
  const route = useMenuEditFormRoute(mode, typeState);
  const { routeIds, setRoute, setRouteIds, setCurrentRoute } = useRouteStates();
  const { getAllCardLayouts, addCardLayout2 } = useCardLayouts();
  const { patchUser } = useUser();
  const { allItems, resetItemIds } = useLeftNavItems();
  const navigate = useNavigate();
  const selectedSharedPage = useRecoilValue(selectedSharedPageAtom);
  const { addCard, getAllCards } = useCards();
  const { setSearchValues } = useSearchValuesState2();

  const handleOnClose = useCallback(() => {
    setType("route");
    setIcon("Dashboard");
    setOpen(false);
  }, [setOpen]);

  const save = useCallback(
    async (newRoute: TRoute) => {
      const newRoutes = [...allItems];
      newRoutes.push(newRoute);
      const newLayouts = [...(await getAllCardLayouts())];
      newLayouts.push({
        route_id: newRoute.id,
        layouts: defaultLayouts,
      });
      await patchUser({ routes: newRoutes, layouts: newLayouts });
    },
    [allItems, getAllCardLayouts, patchUser],
  );

  // [ToDo] ↑↓ 要整理

  const save2 = useCallback(
    async (newRoute: TRoute) => {
      const newRoutes = [...allItems];
      newRoutes.push(newRoute);
      const newCards = [...(await getAllCards())];
      const newLayouts = [...(await getAllCardLayouts())];
      await patchUser({ routes: newRoutes, cards: newCards, layouts: newLayouts });
    },
    [allItems, getAllCardLayouts, getAllCards, patchUser],
  );

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const newRoute = { ...route };
      newRoute.title = String(data.get("title"));
      if (typeState === "route") newRoute.icon = iconState;
      setRoute(newRoute);
      if (mode === "add") {
        const newRouteIds = [...routeIds];
        newRouteIds.push(newRoute.id);
        setRouteIds(newRouteIds);
        resetItemIds();
        await save(newRoute);
      }
      handleOnClose();
      if (typeState === "route") {
        newRoute.url && navigate(newRoute.url);
        setCurrentRoute(newRoute);
      }
    },
    [
      handleOnClose,
      iconState,
      mode,
      navigate,
      resetItemIds,
      route,
      routeIds,
      save,
      setCurrentRoute,
      setRoute,
      setRouteIds,
      typeState,
    ],
  );

  const { alertError } = useAlertPop();

  const handleOnSubmitSharedPage = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!selectedSharedPage) {
        alertError("選択して下さい。", event);
        return;
      }

      const newRoute = { ...(await apiFetchSharedPage(selectedSharedPage.id)) } as TRoute;
      const sharedPageContents = newRoute.sharedPageContents;
      delete newRoute.sharedPageContents;

      newRoute.id = window.crypto.randomUUID();
      newRoute.url = "/" + newRoute.id;
      newRoute.type = "shared_page";
      newRoute.sharedPageId = selectedSharedPage.id;
      delete newRoute.depth;
      delete newRoute.parentId;
      const newRouteIds = [...routeIds];
      newRouteIds.push(newRoute.id);
      setRoute(newRoute);
      setRouteIds(newRouteIds);
      resetItemIds();

      if (sharedPageContents) {
        const layouts = JSON.parse(JSON.stringify(sharedPageContents.layouts)) as TRouteCardLayouts;
        layouts.route_id = newRoute.id;
        for (const card of sharedPageContents.cards) {
          const addedCard = await addCard(card, newRoute);
          await setSearchValues(addedCard, addedCard.search_values);
          Object.keys(layouts.layouts).forEach((key) => {
            layouts.layouts[key].forEach((layout, index) => {
              if (layout.i === card.uuid) layouts.layouts[key][index]["i"] = addedCard.uuid;
            });
          });
        }
        await addCardLayout2(layouts);
      }

      await save2(newRoute);
      handleOnClose();
      newRoute.url && navigate(newRoute.url);
      setCurrentRoute(newRoute);
    },
    [
      addCard,
      addCardLayout2,
      alertError,
      handleOnClose,
      navigate,
      resetItemIds,
      routeIds,
      save2,
      selectedSharedPage,
      setCurrentRoute,
      setRoute,
      setRouteIds,
      setSearchValues,
    ],
  );

  const handleOnIcon = useCallback((icon: any) => setIcon(icon), []);

  const handleOnType = useCallback((type: TType) => setType(type), []);

  useEffect(() => {
    if (!route || mode !== "edit") return;
    route.icon && setIcon(String(route.icon));
    if (route.type) setType(route.type === "shared_page" ? "route" : route.type);
  }, [mode, route]);

  if (!route) return <></>;

  return (
    <StdFormDialog
      title="Edit Menu Form"
      openState={open}
      handleOnSubmit={typeState === "shared_page" ? handleOnSubmitSharedPage : handleOnSubmit}
      handleOnClose={handleOnClose}
    >
      {mode === "add" && (
        <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2 }}>
          <FormLabel component="legend">追加したいもの</FormLabel>
          <FormGroup row>
            <Button
              variant={typeState === "route" ? "contained" : "outlined"}
              onClick={() => handleOnType("route")}
              sx={{ m: 1 }}
            >
              ページ
            </Button>
            <Button
              variant={typeState === "folder" ? "contained" : "outlined"}
              onClick={() => handleOnType("folder")}
              sx={{ m: 1 }}
            >
              フォルダ
            </Button>
            <Button
              variant={typeState === "shared_page" ? "contained" : "outlined"}
              onClick={() => handleOnType("shared_page")}
              sx={{ m: 1 }}
            >
              共有ページ
            </Button>
          </FormGroup>
        </FormControl>
      )}
      {typeState !== "shared_page" && (
        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Menu Title"
          name="title"
          autoFocus
          defaultValue={mode === "edit" ? route.title : ""}
        />
      )}

      {typeState === "route" && route.type !== "folder" && (
        <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2 }}>
          <FormLabel component="legend">Icon</FormLabel>
          <FormGroup row>
            {menuIcons.map((i) => (
              <Button
                key={i}
                variant={i === iconState ? "contained" : "outlined"}
                onClick={() => handleOnIcon(i)}
                sx={{ m: 1 }}
              >
                <Icons iconName={i} />
              </Button>
            ))}
          </FormGroup>
          <Typography variant="body2" sx={{ margin: 2, marginLeft: 5, color: "grey.800" }}>
            他に欲しいアイコンが有ったら、
            <br />
            <Link href="https://mui.com/material-ui/material-icons/#search-material-icons" target="_blank">
              https://mui.com/material-ui/material-icons/#search-material-icons
            </Link>
            <br />
            で探して、津森まで連絡下さい。
          </Typography>
        </FormControl>
      )}
      {typeState === "shared_page" && (
        <ErrorBoundaryProvider>
          <Suspense fallback={<LinearProgress />}>
            <SharedPageList />
          </Suspense>
        </ErrorBoundaryProvider>
      )}
    </StdFormDialog>
  );
};

export default MenuEditFormDialog;
