import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { arrayMove } from "@dnd-kit/sortable";
import { allRoutesSelector, TRoute, useRouteStates } from "models/RouteModel";
import { useCallback } from "react";

const leftNavOpenState = atom<boolean>({
  key: "leftNavOpen",
  default: true,
});

export const useLeftNavOpen = () => {
  const setState = useSetRecoilState(leftNavOpenState);

  const isOpen = useRecoilValue(leftNavOpenState);
  const setOpen = () => setState(true);
  const setClose = () => setState(false);
  const setToggle = () => setState(!isOpen);

  return { isOpen, setOpen, setClose, setToggle };
};

type TLeftNavItem = TRoute;

const leftNavItemState = atomFamily<TLeftNavItem, TRoute["id"]>({
  key: "leftNavItemState",
  default: undefined,
});

const leftNavItemIdsState = atom<TRoute["id"][]>({
  key: "leftNavItemIdsState",
  default: selector({
    key: "leftNavItemIdsStateDefault",
    get: ({ get }) => {
      return get(allRoutesSelector)
        .filter((route) => !route.depth)
        .map((route) => route.id);
    },
  }),
});

const leftNavAllItemIdsState = atom<TRoute["id"][]>({
  key: "leftNavAllItemIdsState",
  default: selector({
    key: "leftNavAllItemIdsStateDefault",
    get: ({ get }) => {
      return get(allRoutesSelector).map((route) => route.id);
    },
  }),
});

const leftNavAllItemIdsSelector = selector<TRoute["id"][]>({
  key: "leftNavAllItemIdsSelector",
  get: ({ get }) => get(leftNavAllItemIdsState),
  set: ({ set }, ids) => set(leftNavAllItemIdsState, ids),
});

const leftNavItemSelector = selectorFamily<TLeftNavItem, TRoute["id"]>({
  key: "leftNavItemSelector",
  get:
    (id) =>
    ({ get }) => {
      const item = get(leftNavItemState(id));
      if (item) return item;
      const routes = get(allRoutesSelector);
      const newItem = [...routes].find((item) => item.id === id);
      return newItem ? newItem : ({} as TLeftNavItem);
    },
  set:
    (id) =>
    ({ set }, item) => {
      set(leftNavItemState(id), item);
    },
});

const leftNavItemsSelector = selector<TLeftNavItem[]>({
  key: "allLeftNavItemsSelector",
  get: ({ get }) => {
    const ids = get(leftNavItemIdsState);
    return ids.map((id) => get(leftNavItemSelector(id)));
  },
  set: ({ set }, items) => {
    if (items instanceof DefaultValue) return;
    items.forEach((item) => set(leftNavItemState(item.id), item));
  },
});

const useLeftNavItem = () => {
  const items = useRecoilValue(leftNavItemsSelector);

  const setItem = useRecoilCallback(({ set }) => (item: TLeftNavItem) => {
    set(leftNavItemSelector(item.id), item);
  });

  const resetItem = useRecoilCallback(({ reset }) => (item: TLeftNavItem) => {
    reset(leftNavItemSelector(item.id));
  });

  return { items, setItem, resetItem };
};

const useLeftNavItemIds = () => {
  const ids = useRecoilValue(leftNavItemIdsState);

  const setIds = useRecoilCallback(({ set }) => (ids: Array<TLeftNavItem["id"]>) => {
    set(leftNavItemIdsState, ids);
  });

  return { ids, setIds };
};

const idsArrayMove = (ids: TRoute["id"][], activeId: TRoute["id"], overId: TRoute["id"]) => {
  const oldIndex = ids.findIndex((id) => id === activeId);
  const newIndex = ids.findIndex((id) => id === overId);
  const result = arrayMove(ids, oldIndex, newIndex);
  return result;
};

export const useLeftNavItems = () => {
  const { items, setItem, resetItem } = useLeftNavItem();
  const { ids: itemIds, setIds: setItemIds } = useLeftNavItemIds();
  const { isOpen, setOpen, setClose } = useLeftNavOpenFolders();
  const { allRoutes: routes, routeIds, setRoute, setRouteIds } = useRouteStates();
  const setOpenFolders = useSetRecoilState(leftNavOpenFoldersState);
  const [allItemIds, setAllItemIds] = useRecoilState(leftNavAllItemIdsSelector);
  const allItems = allItemIds.map((id) => routes.find((route) => route.id === id));

  const userItems = items.filter((item) => item.menuType !== "fixed");
  const fixedItems = allItems.filter((item) => item && item.menuType === "fixed") as TRoute[];

  const setItemsOnDragEnd = (activeId: TRoute["id"], overId: TRoute["id"]) => {
    const activeItem = userItems.find((item) => item.id === activeId) as TRoute;
    setRoute(activeItem);
    resetItem(activeItem);
    setAllItemIds(idsArrayMove(allItemIds, activeId, overId));
    setItemIds(idsArrayMove(itemIds, activeId, overId));
  };

  const closeFolder = useCallback(
    (folderId: TRoute["id"]) => {
      setClose(folderId);
      const newItems = userItems.filter((item) => routes.find((route) => route.id === item.id)?.parentId !== folderId);
      setItemIds(newItems.map((item) => item.id));
    },
    [routes, setClose, setItemIds, userItems],
  );

  const openFolder = useCallback(
    (folderId: TRoute["id"]) => {
      if (isOpen(folderId)) return;
      const children = routes.filter((item) => String(item.parentId) === folderId);
      setOpen(folderId);
      const i = userItems.findIndex((item) => item.id === folderId) + 1;
      const newItems = [...userItems.slice(0, i), ...children, ...userItems.slice(i)];
      setItemIds(newItems.map((item) => item.id));
    },
    [isOpen, routes, setItemIds, setOpen, userItems],
  );

  const setItemsOnCollapse = useCallback(
    (folderId: TRoute["id"], toggletype?: "open" | "close") => {
      if (toggletype === "open") openFolder(folderId);
      else if (toggletype === "close") closeFolder(folderId);
      else if (isOpen(folderId)) closeFolder(folderId);
      else openFolder(folderId);
    },
    [closeFolder, isOpen, openFolder],
  );

  const closeAllFolder = () => {
    const folders = routes.filter((item) => item.type === "folder");
    const folderIds = folders.map((item) => item.id);
    setOpenFolders([]);
    const newItemIds = routes.filter((item) => !folderIds.includes(String(item.parentId))).map((item) => item.id);
    setItemIds(newItemIds);
  };

  const resetItemIds = useRecoilCallback(({ snapshot, reset }) => async () => {
    const allIds = await snapshot.getPromise(leftNavAllItemIdsSelector);
    allIds.forEach((id) => reset(leftNavItemState(id)));
    reset(leftNavItemIdsState);
    reset(leftNavAllItemIdsState);
    reset(leftNavOpenFoldersState);
  });

  const deleteItem = useRecoilCallback(({ snapshot, reset }) => async (target: TRoute) => {
    const newIds = routeIds.filter((id) => id !== target.id);
    setRouteIds(newIds);
    newIds.forEach((id) => reset(leftNavItemState(id)));
    reset(leftNavItemIdsState);
    reset(leftNavAllItemIdsState);
    reset(leftNavOpenFoldersState);
  });

  return {
    items,
    allItems,
    userItems,
    fixedItems,
    setItem,
    setItemIds,
    setAllItemIds,
    setItemsOnDragEnd,
    setItemsOnCollapse,
    closeAllFolder,
    resetItemIds,
    setOpen,
    isOpen,
    deleteItem,
  };
};

const leftNavOpenFoldersState = atom<TRoute["id"][]>({
  key: "leftNavOpenFoldersState",
  default: [],
});

export const useLeftNavOpenFolders = () => {
  const setState = useSetRecoilState(leftNavOpenFoldersState);
  const openIds = useRecoilValue(leftNavOpenFoldersState);
  const setOpen = (openId: TRoute["id"]) => {
    const ids = [...openIds];
    ids.push(openId);
    setState(ids);
  };
  const setClose = (closeId: TRoute["id"]) => {
    setState(openIds.filter((id) => id !== closeId));
  };
  const isOpen = (id: TRoute["id"]) => openIds.includes(id);

  return { openIds, setOpen, setClose, isOpen };
};

const isEditMode = atom<boolean>({
  key: "leftNav/isEditMode",
  default: false,
});
export const useLeftNavIsEditMode = () => useRecoilValue(isEditMode);
export const useSetLeftNavIsEditMode = () => useSetRecoilState(isEditMode);

const editFormOpenState = atom<boolean>({
  key: "leftNav/editFormOpen",
  default: false,
});
export const useMenuEditFormOpen = () => useRecoilValue(editFormOpenState);
export const useSetMenuEditFormOpen = () => useSetRecoilState(editFormOpenState);

export type TMenuEditFormMode = "edit" | "add";
const editFormModeState = atom<TMenuEditFormMode>({
  key: "leftNav/editFormMode",
  default: "edit",
});
export const useMenuEditFormMode = () => useRecoilValue(editFormModeState);
export const useSetMenuEditFormMode = () => useSetRecoilState(editFormModeState);
const editFormRouteState = atom<TRoute>({
  key: "leftNav/editFormRoute",
  default: undefined,
});
export const useMenuEditFormRoute = (mode: TMenuEditFormMode, type: "route" | "folder" | "shared_page"): TRoute => {
  const route = useRecoilValue(editFormRouteState);
  if (mode === "edit") return route;

  const id = window.crypto.randomUUID();
  switch (type) {
    case "route":
      return {
        id: id,
        type: "route",
        page: "Dashboard",
        url: "/" + id,
        depth: 0,
      };
    case "folder":
      return {
        id: id,
        type: "folder",
        depth: 0,
      };
    case "shared_page":
      return {
        id: id,
        type: "shared_page",
        page: "Dashboard",
        url: "/" + id,
        depth: 0,
      };
  }
};
export const useSetMenuEditFormRoute = () => useSetRecoilState(editFormRouteState);

const deleteFormOpenState = atom<boolean>({
  key: "leftNav/deleteFormOpen",
  default: false,
});
export const useMenuDeleteFormOpen = () => useRecoilValue(deleteFormOpenState);
export const useSetMenuDeleteFormOpen = () => useSetRecoilState(deleteFormOpenState);

const deleteFormRouteState = atom<TRoute>({
  key: "leftNav/deleteFormRoute",
  default: undefined,
});
export const useMenuDeleteFormRoute = () => useRecoilValue(deleteFormRouteState);
export const useSetMenuDeleteFormRoute = () => useSetRecoilState(deleteFormRouteState);
