import { useCallback } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import StdFormDialog from "components/common/form/StdFormDialog";
import { TRoute } from "models/RouteModel";
import { TSharePage, useSharePage } from "models/SharePageModel";
import { useUser } from "models/UserModel";
import { useAlertPop } from "components/common/AlertPop";

const openAtom = atom<boolean>({
  key: "SharePageFormDialog/atomOpen",
  default: false,
});

const routeAtom = atom<TRoute>({
  key: "SharePageFormDialog/routeAtom",
  default: undefined,
});

export const SharePageFormStates = () => {
  return { openAtom, routeAtom };
};

const SharePageFormDialog = () => {
  const [open, setOpen] = useRecoilState(openAtom);
  const route = useRecoilValue(routeAtom);
  const { post } = useSharePage();
  const { getUser } = useUser();
  const user = getUser();

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { alertError } = useAlertPop();

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!user || !route) {
        handleOnClose();
        return;
      }
      const data: TSharePage = {
        title: route.title,
        uid: user?.uid,
        routeId: route.id,
      };
      const result = await post(data);
      if (result && result.status === "fail") {
        const message = result.response.data.message;
        if (message.indexOf("existing_uuid")) alertError("このページは既に共有されているので、共有出来ません。", event);
        return;
      }
      handleOnClose();
    },
    [alertError, handleOnClose, post, route, user],
  );

  if (!route) return <></>;

  return (
    <StdFormDialog
      title="Share Page Form"
      text={`ページ "${route.title}" を共有しますか ?`}
      openState={open}
      handleOnSubmit={handleOnSubmit}
      handleOnClose={handleOnClose}
    />
  );
};

export default SharePageFormDialog;
