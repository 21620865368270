import { atom, atomFamily, selector, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import { TUser } from "./UserModel";
import { TRoute, useRouteStates } from "./RouteModel";
import { TCard } from "./CardModel";

export const defaultLayouts = { xl: [], lg: [], md: [], sm: [], xs: [] };

export type TRouteCardLayouts = {
  route_id: TRoute["id"];
  layouts: ReactGridLayout.Layouts;
};

const routeLayoutsState = atomFamily<TRouteCardLayouts, TRouteCardLayouts["route_id"]>({
  key: "layoutsState",
  default: undefined,
});

export type TRouteLayoutIds = TRouteCardLayouts["route_id"][];
const routeLayoutIdsState = atom<TRouteLayoutIds>({
  key: "routeLayoutIdsState",
  default: [],
});

const allRouteLayoutsSelector = selector<TRouteCardLayouts[]>({
  key: "allRouteLayoutsSelector",
  get: ({ get }) => {
    const ids = get(routeLayoutIdsState);
    return ids.map((routeId) => get(routeLayoutsState(routeId)));
  },
});

export const useInitCardLayouts = () => {
  const initLayouts = useRecoilCallback(() => (user: TUser | null, routes: TRoute[]) => {
    let layouts = [] as TRouteCardLayouts[];
    if (user && user.layouts && Array.isArray(user.layouts)) {
      layouts = user.layouts;
    } else if (routes) {
      layouts = routes
        .filter((route) => route.page === "Dashboard")
        .map((route) => {
          return {
            route_id: route.id,
            layouts: defaultLayouts,
          };
        });
    }
    registLayouts(layouts);
  });

  const registLayouts = useRecoilCallback(({ set }) => (layouts: TRouteCardLayouts[]) => {
    const routeIds: TRouteLayoutIds = [];
    layouts.forEach((layout: TRouteCardLayouts) => {
      set(routeLayoutsState(layout.route_id), layout);
      routeIds.push(layout.route_id);
    });
    set(routeLayoutIdsState, routeIds);
  });

  return initLayouts;
};

export type TNewCardPosition = "top" | "bottom" | undefined;

export const useCardLayouts = () => {
  const { currentRoute } = useRouteStates();

  const getAllCardLayouts = useRecoilCallback(({ snapshot }) => async () => {
    const layouts: TRouteCardLayouts[] = await snapshot.getPromise(allRouteLayoutsSelector);
    return layouts;
  });

  const addCardLayout = useRecoilCallback(({ snapshot, set }) => async (card: TCard, position?: TNewCardPosition) => {
    const newLayout = {
      x: -1,
      y: position === "bottom" ? 99999 : -1,
      w: 1,
      h: 5,
      i: card.uuid,
    };
    const routeLayoutes = await snapshot.getPromise(routeLayoutsState(currentRoute.id));
    const newRouteLayouts: TRouteCardLayouts = JSON.parse(JSON.stringify(routeLayoutes));
    Object.keys(newRouteLayouts.layouts).forEach((key) => {
      if (newRouteLayouts.layouts[key].length) {
        let exists = false;
        newRouteLayouts.layouts[key] = newRouteLayouts.layouts[key].map((layout) => {
          exists = layout.i === newLayout.i;
          return exists ? newLayout : layout;
        });
        if (!exists) newRouteLayouts.layouts[key].push(newLayout);
      } else {
        newRouteLayouts.route_id = currentRoute.id;
        newRouteLayouts.layouts[key] = [newLayout];
      }
    });
    set(routeLayoutsState(currentRoute.id), newRouteLayouts);
  });

  // [ToDo] ↑↓ 要整理

  const addCardLayout2 = useRecoilCallback(({ snapshot, set }) => async (newLayouts: TRouteCardLayouts) => {
    const ids = [...(await snapshot.getPromise(routeLayoutIdsState))];
    ids.push(newLayouts.route_id);
    set(routeLayoutIdsState, ids);
    set(routeLayoutsState(newLayouts.route_id), newLayouts);
  });

  const deleteCardLayout = useRecoilCallback(({ snapshot, set }) => async (card: TCard) => {
    const routeLayoutes = await snapshot.getPromise(routeLayoutsState(currentRoute.id));
    const newRouteLayouts: TRouteCardLayouts = JSON.parse(JSON.stringify(routeLayoutes));
    Object.keys(newRouteLayouts.layouts).forEach((key) => {
      const newLayout = newRouteLayouts.layouts[key].filter((layout) => layout.i !== card.uuid);
      newRouteLayouts.layouts[key] = newLayout;
    });
    set(routeLayoutsState(currentRoute.id), newRouteLayouts);
  });

  return { getAllCardLayouts, addCardLayout, addCardLayout2, deleteCardLayout };
};

export const useSetRouteCardLayouts = (route_id: TRoute["id"]) => {
  return useSetRecoilState(routeLayoutsState(route_id));
};

export const useRouteCardLayouts = (routeId: TRoute["id"]) => {
  return useRecoilValue(routeLayoutsState(routeId));
};
